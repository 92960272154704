<template>
  <div class="ratings-component">
    <h2>Most Rated Snacks</h2>
    <div class="segment">
      <ul class="snack-list">
        <li v-for="snack in mostRatedSnacks" :key="snack.id" class="snack-item" @click="goToProductPage(snack.id)">
          <div class="item-box">
            <img :src="snack.imageLink" alt="Product Image" class="product-image">
            <div class="item-details">
              <p><strong>{{ snack.name }}</strong></p>
              <p>{{ snack.brand }}</p>
              <p><img src="../assets/acorn-filled.png" alt="Acorn" class="acorn-image"> <strong>{{ snack.averageRating.toFixed(1) }}</strong> ({{ snack.ratingCount }} Ratings)</p>
              <ul class="comments-list">
                <li v-for="comment in snack.commentsAndAuthors.slice(-3)" :key="comment.comment" class="comment-item">
                  "{{ comment.comment }}" - {{ comment.author }}
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="error" class="error">
        <p>Error fetching data: {{ error }}</p>
      </div>
  </div>
  <div class="ratings-component">
    <h2>Highest Rated Snacks</h2>
    <div class="segment">
      <ul class="snack-list">
        <li v-for="snack in highestRatedSnacks" :key="snack.id" class="snack-item" @click="goToProductPage(snack.id)">
          <div class="item-box">
            <img :src="snack.imageLink" alt="Product Image" class="product-image">
            <div class="item-details">
              <p><strong>{{ snack.name }}</strong> - {{ snack.brand }}</p>
              <p><img src="../assets/acorn-filled.png" alt="Acorn" class="acorn-image"> <strong>{{ snack.averageRating.toFixed(1) }}</strong> ({{ snack.ratingCount }} Ratings)</p>
              <ul class="comments-list">
                <li v-for="comment in snack.commentsAndAuthors.slice(-3)" :key="comment.comment" class="comment-item">
                  "{{ comment.comment }}" - {{ comment.author }}
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
      <div v-if="error" class="error">
        <p>Error fetching data: {{ error }}</p>
      </div>
    </div>
  </template>
  
  <script>
  import { fetchMostRatedSnacks, fetchHighestRatedSnacks } from '@/services/backendApiService';
  
  export default {
  data() {
    return {
      mostRatedSnacks: [],
      highestRatedSnacks: [],
      error: null
    };
  },
  methods: {
    async fetchData() {
      try {
        const mostRatedResponse = await fetchMostRatedSnacks();
        const highestRatedResponse = await fetchHighestRatedSnacks();

        console.log('Most Rated Snacks Response:', mostRatedResponse);

        this.mostRatedSnacks = mostRatedResponse;
        this.highestRatedSnacks = highestRatedResponse;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = error.message;
      }
    },
    goToProductPage(barcodeId) {
      this.$router.push(`/product/${barcodeId}`);
    }
  },
  async mounted() {
    await this.fetchData();
  }
  };
  </script>
  
  <style scoped>
  .ratings-component {
    padding: 20px;
    background-color: #ffffffc7;
    color: rgba(255, 107, 107, 0.95);
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .ratings-component h2 {
    margin: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .segment {
    margin-bottom: 20px;
  }
  
  .snack-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    list-style-type: none;
    padding: 0;
  }
  
  .snack-item {
  background-color: #f9f9f9;
  color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s, color 0.2s;
}

.snack-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #4ecdc5c7;
  color: #fff;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}
  
  .item-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .product-image {
    width: 130px;
    height: 130px;
    object-fit: cover;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }
  
  .item-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
  
  .item-details p {
    margin: 5px 0;
    font-size: 1rem;
    color: #333;
  }
  
  .item-details p strong {
    font-weight: bold;
  }
  
  .acorn-image {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
  }
  
  .comments-list {
    list-style-type: none;
    padding: 0;
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .comment-item {
    background-color: rgba(255, 107, 107, 0.95);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    width: 90%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
  
  .error {
    color: red;
    margin-top: 20px;
  }
  
  @media (max-width: 480px) {
    .snack-list {
      grid-template-columns: 1fr;
    }
  }
  </style>
