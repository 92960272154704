<!-- src/components/FooterComponent.vue -->
<template>
    <footer class="footer">
      <p>&copy; 2024 knuspa.com</p>
      <router-link to="/imprint">Imprint</router-link>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
  };
  </script>
  
  <style scoped>
  .footer {
    background-color: #f8f8f8;
    padding: 20px;
    text-align: center;
    width: 100%;
  }
  </style>