<template>
  <div class="star-rating">
    <span
      v-for="star in 5"
      :key="star"
      class="star"
      :class="{ filled: star <= rating, hover: star <= hoverRating }"
      @click="setRating(star)"
      @mouseover="setHoverRating(star)"
      @mouseleave="resetHoverRating"
    >
      <img :src="star <= hoverRating || star <= rating ? acornFilled : acornGray" alt="acorn" />
    </span>
  </div>
</template>

<script>
import acornFilled from '@/assets/acorn-filled.png';
import acornGray from '@/assets/acorn-empty.png';

export default {
  props: {
    rating: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      hoverRating: 0,
      acornFilled,
      acornGray
    };
  },
  methods: {
    setRating(star) {
      this.$emit('update:rating', star);
    },
    setHoverRating(star) {
      this.hoverRating = star;
    },
    resetHoverRating() {
      this.hoverRating = 0;
    }
  }
};
</script>

<style scoped>
.star-rating {
  display: flex;
  cursor: pointer;
}

.star img {
  width: 2.5rem; /* Adjust size as needed */
  height: 2.5rem; /* Adjust size as needed */
  transition: filter 0.2s; /* Smooth transition for hover effect */
}
</style>