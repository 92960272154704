<template>
  <div class="product-container">
   <div class="product-component" v-if="product">
     <div class="product-header">
       <img :src="product.imageLinks[0]" alt="Product Image" class="product-image" />
       <div class="product-details">
         <h1>{{ product.name }}</h1>
         <p>{{ product.description }}</p>
       </div>
     </div>
     <div class="product-info">
       <div class="ratings">
         <p><img src="@/assets/acorn-filled.png" alt="Acorn Filled" class="acorn-image" /> <strong>{{ averageRating.toFixed(1) }}</strong> ({{ ratingCount }} ratings)</p>
       </div>
       <div class="comments">
         <ul>
           <li v-for="(comment, index) in lastComments" :key="index">
             <p>"{{ comment.comment }}" - {{ comment.userName }}</p>
           </li>
         </ul>
       </div>
     </div>
     <div class="categories">
         <p>
           <span v-for="(category, index) in transformedCategories" :key="index">
             <a :href="`/categories/${category}`">{{ category }}</a>
             <span v-if="index < transformedCategories.length - 1"> > </span>
           </span>
         </p>
       </div>
   </div>
    <div v-else>
      <p>Loading...</p>
    </div>
  </div>
</template>

<script>
import * as backendApiService from '@/services/backendApiService';

export default {
  name: 'ProductComponent',
  props: {
    barcodeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      product: null,
      ratingCount: 0,
      averageRating: 0,
      lastComments: [],
      errorMessage: ''
    };
  },
  computed: {
    transformedCategories() {
      if (!this.product || !this.product.categories) return [];
      return this.product.categories.map(category => category.split(':')[1]);
    }
  },
  created() {
    this.fetchProduct();
  },
  methods: {
    async fetchProduct() {
      try {
        const productResponse = await backendApiService.getProductByBarcodeId(this.barcodeId);
        if (productResponse) {
          this.product = productResponse;
        } else {
          this.errorMessage = 'Product not found.';
        }

        const ratingsResponse = await backendApiService.getRatingsByBarcodeId(this.barcodeId);
        if (ratingsResponse) {
          this.ratingCount = ratingsResponse.ratingCount;
          this.averageRating = ratingsResponse.averageRating;
          this.lastComments = ratingsResponse.lastComments;
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        this.errorMessage = 'Error fetching product. Please try again later.';
      }
    }
  }
};
</script>

<style scoped>
.product-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Ensure it takes the full height of the viewport */
  padding: 20px; /* Add some padding for better spacing */
}

.product-component {
  width: 100%;
  max-width: 600px; /* Set a max-width for the component */
  background-color: #fff; /* Example background color */
  padding: 20px; /* Example padding */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Example box shadow */
  border-radius: 10px; /* Example border radius */
}

.product-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #4ecdc5c7;
  padding: 20px;
  border-radius: 10px;
  color: white;
}
.product-header h1 {
  color: white;
  margin: 0;
}
.product-image {
  max-width: 150px;
  margin-right: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-details {
  flex: 1;
}

.product-info {
  margin-top: 20px;
}

.categories, .ratings, .comments {
  margin-bottom: 20px;
}

.categories p, .ratings p {
  margin: 0;
}

.comments ul {
  list-style-type: none;
  padding-left: 0;
}

.comments li {
  margin-bottom: 10px;
}

.acorn-image {
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
  }

  @media (max-width: 480px) {
  .product-component {
    max-width: 100%; /* Ensure it takes the full width on mobile */
  }
}
</style>