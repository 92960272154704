import axios from 'axios';

const API_BASE_URL = 'https://world.openfoodfacts.org/api/v3/product';
export const getProductById = async (id) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}.json`, { timeout: 5000 });
    return response.data;
  } catch (error) {
    if (error.response) {
      // If the error has a response, return the response data
      return error.response.data;
    } else {
      console.error(`Error fetching product with id ${id}:`, error);
      throw error;
    }
  }
};

// Add more API calls as needed