<!-- src/views/ImprintPage.vue -->
<template>
    <div class="imprint-page">
      <h1>Imprint</h1>
      <p>
        knuspa.com
      </p>
      <p>Created by:</p>
      <p>Nico Greuel</p>
      <p>
        <a href="https://github.com/Greuel" target="_blank">
          <i class="fab fa-github"></i> https://github.com/Greuel
        </a>
      </p>
      <p>
        <i class="fas fa-envelope"></i> greuelnico@gmail.com
      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ImprintPage'
  };
  </script>
  
  <style scoped>
  .imprint-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  i {
    margin-right: 8px;
  }
  </style>