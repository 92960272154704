<!-- views/ProductView.vue -->
<template>
  <div class="product-view-container">
    <ProductComponent :barcodeId="$route.params.barcodeId" />
  </div>
</template>

<script>
import ProductComponent from '@/components/ProductComponent.vue';

export default {
  name: 'ProductView',
  components: {
    ProductComponent
  }
};
</script>

<style scoped>
.product-view-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  margin-top: 10px;
  text-align: center;
  border: 2px solid #ffffff;
}

.product-view-container > div {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  background-color: #fff;
}

.product-view-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  text-align: center;
}

.product-view-container p {
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: center;
}

.product-view-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
</style>