<template>
  <div class="product-details" v-if="product">
    <!-- First Segment -->
    <div class="segment" v-if="product.result.name === 'Product not found'">
      <h1>Product not found</h1>
      <p>
        Please try again. If you think the product should be in our database, please reach out to have it added: support@knuspa.com
      </p>
      <p>
        Or contribute to the Open Food Facts database directly:
        <button @click="navigateToProduct" class="image-button">
            <img src="https://static.openfoodfacts.org/images/logos/off-logo-horizontal-light.svg" alt="Open Food Facts Logo" />
          </button>
      </p>
      <p>
        <router-link to="/">Back to Home</router-link>
      </p>
    </div>
    <!-- Second Segment -->
    <div class="segment" v-if="product.result.name === 'Product found'">
      <h1>{{ product.product.brands_tags[0].charAt(0).toUpperCase() + product.product.brands_tags[0].slice(1) }}</h1>   
      <h2>{{ product.product.product_name_en || product.product.product_name_de || product.product.product_name_fr }}</h2>
      <div class="product-info">
        <div class="product-image">
          <img :src="product.product.selected_images.front.display.fr" :alt="product.name" />
        </div>
        <div class="product-icons">
          <p><img :src="nutriscoreImage" alt="Nutriscore" /></p>
          <p><img :src="ecoscoreImage" alt="Ecoscore" /></p>
        </div>
        <div class="product-facts">
          <h2>(Much) more information at:</h2>
          <button @click="navigateToProduct" class="image-button">
            <img src="https://static.openfoodfacts.org/images/logos/off-logo-horizontal-light.svg" alt="Open Food Facts Logo" />
          </button>
          <p><a href="https://world.openfoodfacts.org/terms-of-use">(c) Open Food Facts contributors</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    nutriscoreImage() {
        const nutriscoreTags = this.product.product.nutriscore_2023_tags;
        if (nutriscoreTags && Array.isArray(nutriscoreTags) && nutriscoreTags.length > 0) {
          const nutriscore = nutriscoreTags[0];
          return `https://static.openfoodfacts.org/images/misc/nutriscore-${nutriscore.toLowerCase()}.svg`;
        }
        return '';
      },
      ecoscoreImage() {
          const ecoscoreTags = this.product.product.ecoscore_tags;
          if (ecoscoreTags && Array.isArray(ecoscoreTags) && ecoscoreTags.length > 0) {
          const ecoscore = ecoscoreTags[0];
          return `https://static.openfoodfacts.org/images/attributes/dist/ecoscore-${ecoscore.toLowerCase()}.svg`;
          }
          return '';
      },
  },
  methods: {
    navigateToProduct() {
      window.open(`https://world.openfoodfacts.org/product/${this.product.product.code}`);
    }
  }
};
</script>

<style scoped>
.segment {
    margin: 0 20px; /* Add equal left and right margins */
}

.segment h2, p {
    margin: 10px; /* Add margin to create space between text and border */
}

.product-details {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.product-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns */
  gap: 20px;
  align-items: center; /* Center items vertically */
}

.product-image img {
  max-width: 100%;
  height: auto;
}

.product-icons {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the icons horizontally */
  gap: 10px;
}

.product-icons img {
  max-width: 200px;
  height: auto;
}

.product-facts {
  text-align: center; /* Center the text inside product-facts */
}

.image-button {
  background: none; /* Remove background color */
  border: none; /* Remove border */
  padding: 0; /* Remove padding */
  display: flex; /* Use flexbox to center the image */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  width: 100%; /* Ensure the button takes full width */
  height: auto; /* Ensure the height adjusts automatically */
}

.image-button img {
  max-width: 100%;
  background-color: white;
  height: auto;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 20px;
  border: 0.5px solid #585656; /* Updated border property */
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  transition: all 0.3s ease;
  box-shadow: 6px -8px 8px rgba(0, 0, 0, 0.1);
}

.image-button:hover img {
  transform: scale(1.30);
}
@media (max-width: 480px) {
  .product-info {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
    gap: 20px;
  }

  .product-icons {
    flex-direction: row;
    justify-content: center;
  }

  .product-icons img {
    max-width: 150px;
  }
  .image-button {
    justify-content: center; /* Ensure the button content is centered */
  }

  .image-button img {
    max-width: 80%; /* Adjust the max-width for smaller screens */
    margin: 0 auto; /* Center the image horizontally */
  }
}
</style>