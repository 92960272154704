<template>
    <button class="cta-button">
      <slot></slot>
    </button>
</template>
  
<script>
export default {
  name: 'ButtonComponent'
};
</script>
  
```vue
<style scoped>
.cta-button {
  display: inline-block;
  background-color: #FF6B6B;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 20px;
  border: 0.5px solid #585656; /* Updated border property */
  text-decoration: none;
  font-weight: bold;
  font-size: large;
  transition: all 0.3s ease;
  box-shadow: 6px -8px 8px rgba(0,0,0,0.1);
}
.cta-button:hover {
  background-color: #FF6B6B;
  transform: scale(1.30);
}
.cta-button:active {
  background-color: #4ECDC4; /* Change background color when pressed */
  transform: scale(1.15); /* Slightly reduce the scale when pressed */
  box-shadow: 4px -6px 6px rgba(0,0,0,0.1); /* Adjust shadow to give a pressed effect */
}
</style>