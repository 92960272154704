<template>
    <header>
      <nav class="container">
        <div class="logo-container">
            <img src="../assets/squirrel.png" alt="Knuspa logo" class="logo-image">
            <h1 class="logo-text">knuspa.com</h1>
        </div>
        <button class="menu-toggle" aria-label="Toggle menu" @click="toggleMenu">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="nav-links" :class="{ active: menuActive }">
        <router-link to="/" @click="closeMenu">Home</router-link>
        <router-link to="/discover" @click="closeMenu">Discover</router-link>
        <router-link to="/rate" @click="closeMenu">Rate a Snack</router-link>
        <router-link to="/profile" @click="closeMenu">Profile</router-link>
        <router-link to="/imprint">Imprint</router-link>
      </div>
      </nav>
    </header>
  </template>
  
  <script>
  export default {
    data() {
      return {
        menuActive: false
      };
    },
    methods: {
      toggleMenu() {
        this.menuActive = !this.menuActive;
      },
      closeMenu() {
        this.menuActive = false;
      }
    }
  };
  </script>
  
  <style scoped>
  /* Your CSS code (unchanged) */
  body {
      font-family: 'Poppins', sans-serif;
      margin: 0;
      padding: 0;
      background-color: #F7F7F7;
      color: #333;
    }
    .container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 20px;
    }
    header {
      background-color: #FF6B6B;
      color: white;
      padding: 5px 0;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }
    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
    }
    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .logo-image {
      width: 140px;
      height: 140px;
    }
    .logo-text {
      font-size: 2rem;
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-shadow: 2px 2px 0px #665a5a;
    }
    .nav-links a {
      color: #FFFFFF;
      text-decoration: none;
      margin-left: 20px;
      font-weight: 700;
      font-size: 1.1rem;
      text-transform: uppercase;
      transition: all 0.3s ease;
    }
    .nav-links a:hover {
      color: #4ECDC4;
      transform: scale(1.3);
    }
    .hero {
      background-color: #4ECDC4;
      border-radius: 15px;
      padding: 40px;
      margin-top: 20px;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    }
    .hero h1 {
      font-size: 3rem;
      color: #FFFFFF;
      margin-bottom: 20px;
    }
    .hero p {
      font-size: 1.2rem;
      color: #FFFFFF;
      margin-bottom: 30px;
    }
    .cta-button {
      display: inline-block;
      background-color: #FF6B6B;
      color: white;
      padding: 12px 24px;
      border-radius: 25px;
      text-decoration: none;
      font-weight: bold;
      transition: all 0.3s ease;
    }
    .cta-button:hover {
      background-color: #d1d11e;
      transform: scale(1.05);
    }
    .features {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }
    .feature {
      flex: 1;
      background-color: white;
      border-radius: 15px;
      padding: 20px;
      margin: 0 10px;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0,0,0,0.1);
      transition: all 0.3s ease;
    }
    .feature:hover {
      transform: translateY(-10px);
    }
    .feature img {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
    }
    .feature h2 {
      color: #FF6B6B;
      margin-bottom: 10px;
    }
    .menu-toggle {
      display: none;
      background: none;
      border: none;
      cursor: pointer;
      padding: 10px;
      z-index: 1000;
    }
    .menu-toggle span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #FFFFFF;
      margin: 5px 0;
      transition: all 0.3s ease;
    }
    .menu-toggle.active span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    .menu-toggle.active span:nth-child(2) {
      opacity: 0;
    }
    .menu-toggle.active span:nth-child(3) {
      transform: rotate(-45deg) translate(7px, -6px);
    }
    @media (max-width: 1024px) {
      .logo-image {
        width: 80px;
        height: 80px;
      }
      .logo-text {
        font-size: 1.2rem;
      }
      .features {
        flex-direction: column;
      }
      .feature {
        margin: 10px 0;
      }
      .snack-item {
        width: calc(50% - 10px);
      }
      .menu-toggle {
        display: block;
      }
      .nav-links {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 107, 107, 0.95);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
      .nav-links.active {
        display: flex;
      }
      .nav-links a {
        margin: 15px 0;
        font-size: 1.5rem;
      }
    }
    .rewrite-animation::after {
      content: "knuspa.nom";
      display: inline-block;
      animation: rewrite 5s;
    }
    
    @keyframes rewrite {
      0% {
        content: "knuspa.com";
      }
      10% {
        content: "knuspa.co";
      }
      20% {
        content: "knuspa.c";
      }
      30% {
        content: "knuspa.";
      }
      60% {
        content: "knuspa.n";
      }
      70% {
        content: "knuspa.no";
      }
      100% {
        content: "knuspa.nom";
      }
    }
  </style>