import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue'; // Adjust the path as necessary
import DiscoverPage from '../views/DiscoverPage.vue'; // Adjust the path as necessary
import RatingPage from '@/views/RatingPage.vue';
import ProductView from '@/views/ProductView.vue';
import ProfilePage from '@/views/ProfilePage.vue';
import LoginPage from '@/views/LoginPage.vue';
import ImprintPage from '@/views/ImprintPage.vue';
import { isAuthenticated } from '@/services/authService';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
  },
{
  path: '/discover',
  name: 'DiscoverPage',
  component: DiscoverPage,
},
{
  path: '/rate',
  name: 'RatingPage',
  component: RatingPage,
},
{
  path: '/product/:barcodeId',
  name: 'ProductView',
  component: ProductView
},
{
  path: '/profile',
  name: 'Profile',
  component: ProfilePage,
  meta: { requiresAuth: true }
},
{
  path: '/login',
  name: 'Login',
  component: LoginPage
},
{
  path: '/imprint',
  name: 'Imprint',
  component: ImprintPage
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;