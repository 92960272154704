<template>
    <div :class="featureClass" @mouseover="hover = true" @mouseleave="hover = false" :style="{ backgroundColor: hover ? '#4ecdc5c7' : '#fff' }">
      <img :src="icon" :alt="alt" class="feature-icon" />
      <h2 class="feature-title">{{ title }}</h2>
      <div class="feature-description">{{ description }}</div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      featureClass: {
        type: String,
        default: ''
      },
      icon: {
        type: String,
        required: true
      },
      alt: {
        type: String,
        required: true
      },
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        hover: false
      };
    }
  };
  </script>
  <style scoped>
  /* Add your feature styles here */
  .feature {
    flex: 1;
    background-color: white;
    border-radius: 15px;
    padding: 20px;
    margin: 0 10px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
  }
  .feature:hover {
    transform: translateY(-10px);
  }
  .feature img {
    width: 80px;
    height: 80px;
    margin-bottom: 15px;
  }
  .feature h2 {
    color: #FF6B6B;
    margin-bottom: 10px;
  }
  </style>