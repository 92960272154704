<template>
  <div>
    <main class="container">
      <section class="hero">
        <h1>Welcome to <span class="rewrite-animation"></span></h1>
        <p>Discover, rate, and share your favorite snacks with the world!</p>
        <router-link to="/rate">
          <ButtonComponent>Rate a Snack</ButtonComponent>
        </router-link>
      </section>

      <section class="features">
        <FeatureComponent
          v-for="(feature, index) in features"
          :key="index"
          :featureClass="'feature'"
          :icon="feature.icon"
          :alt="feature.alt"
          :title="feature.title"
          :description="feature.description"
        >
        </FeatureComponent>
      </section>
    </main>
  </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';
import FeatureComponent from '@/components/FeatureComponent.vue';

export default {
  components: {
    ButtonComponent,
    FeatureComponent
  },
  data() {
    return {
      menuActive: false,
      features: [
        {
          icon: require("../assets/barcode_scanner.png"),
          alt: "Barcode scanner icon",
          title: "Barcode Scanner",
          description: "Instantly look up and rate snacks by scanning their barcode."
        },
        {
          icon: require("../assets/discover.png"),
          alt: "Rating icon",
          title: "Discover Snacks",
          description: "Explore top rated snacks to find a cure for your cravings."
        },
        {
          icon: require("../assets/achievments.png"),
          alt: "Achievement icon",
          title: "Achievements",
          description: "Earn snackxperience and level up as you explore the world of snacks.",
        }
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.menuActive = !this.menuActive;
    },
    closeMenu() {
      this.menuActive = false;
    },
  }
};
</script>

<style scoped>
/* Your CSS code (unchanged) */
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #F7F7F7;
    color: #333;
  }
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  header {
    background-color: #FF6B6B;
    color: white;
    padding: 15px 0;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
  }
  .logo-container {
    display: flex;
    align-items: center;
  }
  .logo-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .logo-text {
    font-size: 2rem;
    font-weight: bold;
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 2px 0px #4ecdc5c7;
  }
  .nav-links a {
    color: #FFFFFF;
    text-decoration: none;
    margin-left: 20px;
    font-weight: 700;
    font-size: 1.1rem;
    text-transform: uppercase;
    transition: all 0.3s ease;
  }
  .nav-links a:hover {
    color: #00ffee;
    transform: scale(1.1);
  }
  .hero {
    background-color: #4ecdc5c7;
    border-radius: 15px;
    padding: 40px;
    margin-top: 10px;
    text-align: center;
    border: 2px solid #ffffff;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px #000000;
  }

  .hero h1 {
    font-size: 3rem;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  .hero p {
    font-size: 1.2rem;
    color: #000000;
    margin-bottom: 30px;
  }

  .features {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  .menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1000;
  }
  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #FFFFFF;
    margin: 5px 0;
    transition: all 0.3s ease;
  }
  .menu-toggle.active span:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  .menu-toggle.active span:nth-child(2) {
    opacity: 0;
  }
  .menu-toggle.active span:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
  @media (max-width: 1024px) {
    .features {
      flex-direction: column;
    }
    .feature {
      margin: 10px 0;
    }
    .menu-toggle {
      display: block;
    }
    .nav-links {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(255, 107, 107, 0.95);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }
    .nav-links.active {
      display: flex;
    }
    .nav-links a {
      margin: 15px 0;
      font-size: 1.5rem;
    }
  }
  .rewrite-animation::after {
    content: "knuspa.nom";
    display: inline-block;
    animation: rewrite 5s;
  }

  .sliding-image {
    position: absolute;
    top: 30%;
    right: -100px;
    transform: translateY(-50%);
    animation: slideIn 5s forwards;
  }

  @keyframes slideIn {
    0% {
      right: -200px;
    }
    100% {
      right: 25%;
    }
  }
  
  @keyframes rewrite {
    0% {
      content: "knuspa.com";
    }
    10% {
      content: "knuspa.co";
    }
    20% {
      content: "knuspa.c";
    }
    30% {
      content: "knuspa.";
    }
    60% {
      content: "knuspa.n";
    }
    70% {
      content: "knuspa.no";
    }
    100% {
      content: "knuspa.nom";
    }
  }
</style>
