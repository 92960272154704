<template>
    <div class="profile-page">
      <h1>Profile</h1>
      <form @submit.prevent="updateProfile">
        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" v-model="user.name" id="name" required />
        </div>
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="user.email" id="email" required />
        </div>
        <div class="form-group">
          <label for="bio">Bio:</label>
          <textarea v-model="user.bio" id="bio"></textarea>
        </div>
        <ButtonComponent type="submit">Update Profile</ButtonComponent>
      </form>
      <ButtonComponent @click="logout" class="logout-button">Logout</ButtonComponent>
    </div>
  </template>
  
  <script>
  import ButtonComponent from '@/components/ButtonComponent.vue';
  
  export default {
    components: {
      ButtonComponent
    },
    data() {
      return {
        user: {
          name: '',
          email: '',
          bio: ''
        }
      };
    },
    methods: {
      updateProfile() {
        // Logic to update the profile
      },
      logout() {
        localStorage.removeItem('isAuthenticated');
        this.$router.push('/login');
      }
    }
  };
  </script>
  
  <style scoped>
  .profile-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input, textarea {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  .logout-button {
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .logout-button:hover {
    background-color: #ddd;
  }
  </style>