<template>
    <div class="login-page">
      <h1>Login / Sign Up</h1>
      <form @submit.prevent="login">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" v-model="email" id="email" required />
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" v-model="password" id="password" required />
        </div>
        <ButtonComponent type="submit" @click="login" class="login-button">Login</ButtonComponent>
      </form>
    </div>
  </template>
  
  <script>
  import ButtonComponent from '@/components/ButtonComponent.vue';
  
  export default {
    components: {
      ButtonComponent
    },
    data() {
      return {
        email: '',
        password: '',
        adminCredentials: {
          email: 'admin@example.com',
          password: 'admin123'
        }
      };
    },
    methods: {
      login() {
        const { email, password } = this;
        const { adminCredentials } = this;
  
        if (email === adminCredentials.email && password === adminCredentials.password) {
          localStorage.setItem('isAuthenticated', 'true');
          this.$router.push('/profile');
        } else {
          alert('Invalid email or password');
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login-page {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
  }
  .form-group {
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  .login-button {
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .login-button:hover {
    background-color: #FF6B6B;
    transform: scale(1.30);
  }
  </style>