<template>
    <div>
        <main>
            <div class="discover-page">
                <h1>Discover Snacks</h1>
                <!-- Add the RatingsComponent here -->
            </div>
            <div>
                <RatingsComponent />
            </div>
        </main>
    </div>
</template>

<script>
import RatingsComponent from '@/components/RatingsComponent.vue';

export default {
    name: 'DiscoverPage',
    components: {
        RatingsComponent
    }
};
</script>

<style scoped>
.discover-page {
    padding: 20px;
    background-color: #4ecdc5c7;
    border-radius: 15px;
    padding: 40px;
    margin-top: 10px;
    text-align: center;
    border: 2px solid #ffffff;
}

.discover-page h1 {
    margin-bottom: 20px;
    color: #fff;
}
</style>