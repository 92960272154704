import axios from 'axios';

const BASE_URL = process.env.VUE_APP_BACKEND_BASE_URL || 'http://localhost:3000/api';

export async function fetchMostRatedSnacks() {
  const response = await axios.get(`${BASE_URL}/snacks/most-rated`);
  return response.data;
}

export async function fetchHighestRatedSnacks() {
  const response = await axios.get(`${BASE_URL}/snacks/highest-rated`);
  return response.data;
}

export async function submitRating(ratingData) {
    const response = await axios.post(`${BASE_URL}/submitRating`, ratingData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}

export async function submitProduct(productData) {
    const response = await axios.post(`${BASE_URL}/updateProduct`, productData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}

export async function getProductByBarcodeId(barcodeId) {
    const response = await axios.get(`${BASE_URL}/products/${barcodeId}`);
    return response.data;
  }

export async function getRatingsByBarcodeId(barcodeId) {
  const response = await axios.get(`${BASE_URL}/ratings/${barcodeId}`);
  return response.data;
}