<template>
   <div class="parent-container">
    <div class="rating-page">
      <div class="segment">
        <h1>Check-in & Rate a Snack</h1>
        <p> You need to be logged in to receive snackxperience.</p>
        <div :class="{'scanner-container': true, 'active': showScanner}" id="scanner-container"></div>
        <ButtonComponent class="scan-button" @click="toggleScanner">
          {{ showScanner ? 'Stop Barcode Scanner' : 'Start Barcode Scanner' }}
        </ButtonComponent>
        <div v-if="successMessage" class="barcode-detected success-message">
        {{ successMessage }}
         </div>
        <h2>or enter Barcode directly:</h2>
        <form @submit.prevent="fetchProductDetails">
            <input type="text" v-model="barcode" id="barcode" required />
            <ButtonComponent type="submit">Fetch Product Details</ButtonComponent>
        </form>
    </div>

    <div v-if="product && product.result && product.result.name === 'Product found'" class="segment">
        <ProductDetails :product="product"/>
    </div>

    <div v-if="product && product.result && product.result.name === 'Product found'" class="segment">
        <div class="rating">
            <h2>Rate this product:</h2>
            <StarRating v-model:rating="rating" />
        </div>

        <div class="review">
            <h2>Leave a review:</h2>
            <textarea v-model="review" id="review"></textarea>
        </div>
    
        <ButtonComponent @click="handleSubmit">Submit Rating</ButtonComponent>

        <div v-if="showSuccessAnimation" class="success-animation">
          <div class="success-message-container">
          <div class="acorns">
            <img src="@/assets/squirrel.png" alt="squirrel" class="acorn-icon" />
          </div>
            <p class="success-message">Nom Nom, thanks for rating!</p>
          </div>
        </div>
    </div>

    </div>
    </div>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue';
import { getProductById } from '@/services/openfoodApiService.js';
import { submitRating } from '@/services/backendApiService.js';
import { submitProduct } from '@/services/backendApiService.js';
import ProductDetails from '@/components/ProductDetails.vue';
import StarRating from '@/components/StarRating.vue';
import 'animate.css';
import Quagga from 'quagga';

const barcodeService = {
  initScanner: (onDetected) => {
    Quagga.init({
      inputStream: {
        type: 'LiveStream',
        target: document.querySelector('#scanner-container'), // Ensure the target element exists
        constraints: {
          width: 640,
          height: 480,
          facingMode: 'environment' // or 'user' for front camera
        }
      },
      decoder: {
        readers: ['code_128_reader', 'ean_reader', 'ean_8_reader', 'code_39_reader', 'code_39_vin_reader', 'codabar_reader', 'upc_reader', 'upc_e_reader', 'i2of5_reader']
      }
    }, (err) => {
      if (err) {
        console.error('Error initializing Quagga:', err);
        return;
      }
      Quagga.start();
    });

    Quagga.onDetected(onDetected);
  },

  stopScanner: () => {
    Quagga.stop();
    document.querySelector('#scanner-container').innerHTML = '';
  }
};

export default {
    components: {
        ButtonComponent,
        ProductDetails,
        StarRating
    },
    data() {
        return {
            barcode: '',
            product: {},
            rating: 1,
            review: '',
            userId: null,
            showSuccessAnimation: false,
            showScanner: false,
        };
    },
    computed: {
      lastCategory() {
        if (this.product && this.product.product && this.product.product.categories_hierarchy) {
          return this.product.product.categories_hierarchy[this.product.product.categories_hierarchy.length - 1];
        }
        return '';
      }
  },
    methods: {
      toggleScanner() {
      if (!this.showScanner) {
        barcodeService.initScanner(this.onDetected);
      } else {
        this.showScanner = true;
        barcodeService.stopScanner;
        }
      this.showScanner = !this.showScanner;
    },
      onDetected(result) {
        this.barcode = result.codeResult.code;
        this.successMessage = 'Barcode detected!';
        barcodeService.stopScanner(); // Stop scanning after detecting a barcode
        this.showScanner = false; // Make the scanner-container small again
      },
      async fetchProductDetails() {
          try {
              this.product = await getProductById(this.barcode);
          } catch (error) {
              console.error('Error fetching product details:', error);
          }
      },
      async submitRating() {
      const ratingData = {
        barcodeId: this.barcode,
        rating: this.rating,
        review: this.review,
        userId: this.userId,
      };
      // Log the ratingData object for debugging
      console.log('Sending rating data to backend:', ratingData);

      try {
        await submitRating(ratingData);

        this.showSuccessAnimation = true;
        setTimeout(() => {
          this.showSuccessAnimation = false;
        }, 5000); // Hide animation after 5 seconds
        this.rating = null;
        this.review = '';
      } catch (error) {
        console.error('Error submitting rating:', error);
        alert('An error occurred while submitting your rating');
      }
    },

    async submitProduct() {
        const productData = {
          name: (this.product.product.product_name_en || this.product.product.product_name_de || this.product.product.product_name_fr) || 'Name Unknown',
          barcodeId: this.barcode,
          brand: (this.product.product.brands) || 'Brand Unknown',
          imageLinks: [(this.product.product.image_front_small_url) || (this.product && this.product.image_front_url) || (this.product && this.product.image_thumb_url) || 'Not Found'],
          categories: (this.product.product.categories_tags) || ['uncategorized'],
        };
      // Log the productData object for debugging
      console.log('Sending product data to backend:', productData);
      
      try {
        await submitProduct(productData);
      } catch (error) {
        console.error('Error submitting product:', error);
        alert('An error occurred while submitting your product');
      }
    },
    async handleSubmit() {
      await this.submitRating();
      await this.submitProduct();
    }
    }
};
</script>

<style scoped>
.parent-container {
  display: flex;
  justify-content: center; /* Center the child horizontally */
  align-items: center; /* Center the child vertically if needed */
  width: 100%;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.rating-page {
    max-width: 1200px;
    color: white;
    margin: 10px auto;
    padding: 20px;
    background-color: #ffffffc7; /* Light background color for visibility */
    width: 100%; /* Ensure the rating-page takes full width */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}
.rating-page h1{
    text-align: center;
    color: white;
}
.rating-page p {
    margin-bottom: 20px;
    color: black;
}
.rating-page form {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center; /* Align items in the center */
}

.rating-page form button {
    margin-bottom: 15px;
    width: fit-content; /* Set width to fit the content */
    margin: 0 auto; /* Center the button horizontally */
}
.rating-page form label {
    margin-bottom: 5px;
}
.rating-page form input {
    padding: 5px;
    margin-bottom: 10px;
}
.rating-page form button {
    margin-bottom: 15px;

}
.rating-page .rating,
.rating-page .review {
    margin-top: 20px;
    margin: 0 auto; /* Center the button horizontally */
}
.rating-page .rating label,
.rating-page .review label {
    display: block;
    margin-bottom: 10px;
}
.rating-page .rating select {
    padding: 5px;
    width: 50px;
}

.rating-page .review textarea {
    width: 50%;
    box-sizing: border-box;
    margin-bottom: 10px;
}
.cta-button {
  font-size: medium;
}
.cta-button:hover {
  transform: scale(1.10);
}
.rating-page .rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.rating-page .rating label {
  display: block;
  margin-bottom: 5px;
}

.rating-page .rating .star-rating {
  display: flex;
}

.success-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000;
}
.success-message-container {
    background-color: #FF6B6B;
    opacity: 1;
    border: 2px solid white;
}
.success-message {
    font-size: 1.5em;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 1; /* Ensure it is not transparent */
}
.acorn-icon {
  width: 100px;
  height: 100px;
  animation: wiggle 1s infinite;
}
.scan-button {
  margin-top: 10px; /* Add margin below the button */
  margin-bottom: 10px;
}
.acorns {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

@keyframes wiggle {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(-10deg);
    }
    40% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(10deg);
    }
    100% {
        transform: rotate(0);
    }
}
.scanner-container {
  width: 0;
  height: 0;
  overflow: hidden;
  transition: width 0.3s, height 0.3s;
}
.scanner-container.active {
  width: 640px;
  height: 480px;
}
.segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px #000000;
  background-color: #4ecdc5c7;
  border: 2px solid #ffffff;
  margin-bottom: 20px;
  border-radius: 10px;
}

.segment h2, p {
    margin: 10px; /* Add margin to create space between text and border */
    color: black;
}
/* Media query for mobile devices */
@media (max-width: 480px) {
  .segment {
    padding: 10px;
  }
  .scan-button {
    margin-bottom: 10px; /* Adjust margin for smaller screens */
    margin-top: 10px;
  }
  #scanner-container {
    max-width: 100%;
    height: auto;
  }
  .product-details {
    padding: 10px;
  }
}
</style>